<template>
  <div class="p-pegas" v-loading="loadingData"> 
    <div class="deal-task-tracker">
      <div class="content-in__header d-flex align-center">
        <div class="content-in__title filter-title">
          <h1 class="content-title m-0" :class="mode ? 'content__titleday' : 'content__titlenight'">{{ $t("message.deals") }}</h1>
          <el-form ref="form" :model="filterForm" class="d-flex el-form-item-0">
            <el-form-item>
              <select-company
                :size="'medium'"
                :placeholder="$t('message.company')"
                :id="filterForm.company_id"
                :class="mode ? 'input__day' : 'input__night'"
                :deal_users="true"
                v-model="filterForm.company_id"
              >
              </select-company>
            </el-form-item>

            <el-form-item>
              <select-client
                :size="'medium'"
                :placeholder="$t('message.client')"
                :id="filterForm.client_id"
                :class="mode ? 'input__day' : 'input__night'"
                :company_id="filterForm.company_id"
                v-model="filterForm.client_id"
              >
              </select-client>
            </el-form-item>

            <el-form-item v-if="authUser.is_super_admin">
              <select-user
                :size="'medium'"
                :placeholder="$t('message.user')"
                :class="mode ? 'input__day' : 'input__night'"
                :id="filterForm.user_id"
                v-model="filterForm.user_id"
              >
              </select-user>
            </el-form-item>

            <el-form-item>
              <select-filial
                :size="'medium'"
                :placeholder="$t('message.filial')"
                :class="mode ? 'input__day' : 'input__night'"
                :id="filterForm.filial_id"
                v-model="filterForm.filial_id"
              >
              </select-filial>
            </el-form-item>
            
            <el-form-item :class="mode ? 'checkbox__day' : 'checkbox__night'">
              <el-checkbox v-model="filterForm.showDealsWithOffer" label="Сделки с предложением" border style="background: white"></el-checkbox>
            </el-form-item>

          </el-form>
        </div>
        <div class="content-in__setting d-flex ml-auto">
          <span class="align-items-center align-self-center pr-2">
            <span :class="mode ? 'price__day' : 'price__night blue__night'" class="el-tag el-tag--light">
              {{ $t("message.total_amount") }}: {{ totalSumm | formatMoney(2) }}
            </span>
          </span>
          <div class="custom__button ">
            <el-button
              v-if="permissions.some(per => per.slug == 'boards.create')"
              type="primary"
              icon="el-icon-s-order"
              size="small"
              @click="drawerBoardCreate = true"
              >{{ $t("message.create_board") }}</el-button
            >
          </div>
        </div>
      </div>

      <div class="deal-track-wrap mt-5" :class="mode ? 'scrollx__day' : 'scrollx__night'">
          <draggable v-model="rows" @change="board" class="d-flex">
            <div
              class="deal-track-items rounded hidden"
              :class="mode ? 'deal__backday' : 'deal__backnight'"
              v-for="(row, index) in rows"
              :key="row.id"
            >
              <div
                class="
                  deal-track-header
                  content-title content-title--medium
                  p-3
                  d-flex
                  f-between
                  align-center
                "
                :style="{ background: row.color }"
              >
                <span class="d-block w-100 pointer"> {{ row.name }}</span>
                <div class="save-changes mx-3 pointer transition">
                  <i class="el-icon-success"></i>
                </div>

                <div class="track-header-menu pointer">
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      <i
                        class="el-icon-more-outline text-white"
                        style="font-size: 18px"
                      ></i>
                    </span>

                    <el-dropdown-menu
                      slot="dropdown"
                      class="task-track-dropdown"
                    >
                      <el-dropdown-item>
                        <el-button
                          v-if="authUser.is_super_admin && permissions.some(per => per.slug == 'boards.update')"
                          type="text"
                          icon="el-icon-edit"
                          size="medium"
                          @click="editTaskTitle(row)"
                        >
                          {{ $t("message.update") }}
                        </el-button>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <el-button
                          v-if="authUser.is_super_admin && permissions.some(per => per.slug == 'boards.delete')"
                          type="text"
                          icon="el-icon-delete"
                          size="medium"
                          @click="deleteBoard(row)"
                        >
                          {{ $t("message.delete") }}
                        </el-button>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>

              <div class="summa-task" :class="mode ? 'summa__day' : 'summa__night'">
                {{ boardDealsSumm[index] | formatMoney(2) }}
              </div>

              <div :class="mode ? 'plus__day' : 'plus__night'" class="add-task" @click="permissions.some(per => per.slug == 'deals.create') ? addModalCreate(row, true) : ''">
                <i class="el-icon-circle-plus-outline"></i>
              </div>

                <div class="deal-track-body p-3" :class="mode ? 'overflow__day' : 'overflow__night'">
                  <draggable
                    class="list-group"
                    :list="row.deals"
                    :group="{ name: 'row' }"
                    @change="deal($event, row)"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <transition-group
                      tag="p"
                      type="transition"
                      :name="!drag ? 'flip-list' : null"
                    >
                      <li
                        class="list-group-item"
                        v-for="element in row.deals"
                        :key="element.id"
                        @dblclick="permissions.some(per => per.slug == 'deals.update') ? addModalEdit(row, element, false) : ''"
                        type="primary"
                      >
                        <div class="deal-task-items p-3 mb-3" :class="mode ? 'tasks__day' : 'tasks__night'">
                          <div class="d-flex f-between mb-2">
                            <span class="nomer-deal-task" :class="mode ? 'text__day' : 'text__white'">
                              <span class="pr-2">№{{ element.id }}</span>
                              
                              <span class="offer_style" v-if="element.offer_count > 0"><i class="offer_style el-icon-document"></i>  {{ element.offer_count }}</span>
                            </span>
                            <div class="name-tesk" :class="mode ? 'text__day' : 'text__white'">
                                {{ element.name }}
                            </div>
                          </div>

                          <div class="name-compami-deal-task mb-2">
                          
                          </div>
                          
                          <div class="type-task-big mb-2">
                            <div class="type-task" :class="mode ? 'text__day' : 'text__night1'">{{ $t('message.company_y') }}:</div>
                            <div class="type-task-name" :class="mode ? 'text__day' : 'text__white'">{{ element.filial ? element.filial.name : ''}}</div>
                          </div>

                          <div class="type-task-big mb-2">
                            <div class="type-task" :class="mode ? 'text__day' : 'text__night1'">{{ $t('message.deal_type') }}:</div>
                            <div class="type-task-name" :class="mode ? 'text__day' : 'text__white'">{{ element.deal_type ? element.deal_type.name : ''}}</div>
                          </div>

                          <div class="type-task-big mb-2">
                            <div class="type-task" :class="mode ? 'text__day' : 'text__night1'">{{ $t('message.country') }}</div>
                            <div class="type-task-name" :class="mode ? 'text__day' : 'text__white'">{{ element.country ? element.country.name : ''}}</div>
                          </div>

                          <div class="user-summa-deal-task">
                            <span >
                              <a :class="mode ? 'text__day' : 'text__night1'" href="#" class="link-color">
                                {{ element.user ? element.user.name : '' }}</a
                              >
                            </span>
                            <span :class="mode ? 'text__day' : 'text__white'"
                              >{{ element.money_amount | formatNumber(2) }}
                              {{ element.currency ? element.currency.code : ''}}</span
                            >
                          </div>

                          <div class="bottom-data-task">
                              <span class="data-deal-task-end">
                                <i class="el-icon-time"></i>
                                {{ formatDate(element.begin_date) }}
                              </span>
                              <span class="data-deal-task-start"
                                :class="dayDiff(element) >= 0 ? 'coloa2' : 'coloa3'">
                                <i class="el-icon-time"></i>
                                {{ formatDate(element.end_date) }}
                              </span>
                              
                              <span class="data-deal-task-start" :class="dayDiff(element) >= 0 ? 'coloa2' : 'coloa3'">
                                  <i class="el-icon-date"></i>
                                   {{dayDiff(element)}}
                              </span>

                              <span class="data-deal-task-start color_6" style="float:right">
                                  <i class="el-icon-edit"></i> <span></span>{{Math.round(element.data_percentage)}} %
                              </span>
                            </div>
                        </div>
                      </li>
                    </transition-group>
                  </draggable>
                </div>
            </div>
          </draggable>
      </div>

      <!-- Create New Task -->
      <el-dialog
        class="create-task-modal modal-style-m"
        :title="$t('message.board_name')"
        :with-header="false"
        :visible.sync="drawerBoardCreate"
        ref="drawerBoardCreate"
        @opened="drawerOpened('drawerBoardCreateChild')"
        @closed="drawerClosed('drawerBoardCreateChild')"
        width="30%"
      >
        <crm-create-board
          :selectedItem="selectedItem"
          ref="drawerBoardCreateChild"
          drawer="drawerBoardCreate"
        ></crm-create-board>
      </el-dialog>
      <!-- End Create New Task -->

      <!-- Edit Task Title -->
      <el-dialog
        class="create-task-modal modal-style-m"
        :title="$t('message.board_name')"
        :with-header="false"
        :visible.sync="drawerBoardUpdate"
        ref="drawerBoardUpdate"
        @opened="drawerOpened('drawerBoardUpdateChild')"
        @closed="drawerClosed('drawerBoardUpdateChild')"
        width="30%"
      >
        <crm-update-board
          :selectedItem="selectedItem"
          ref="drawerBoardUpdateChild"
          drawer="drawerBoardUpdate"
        ></crm-update-board>
      </el-dialog>
      <!-- Edit End Task Title -->

      <div class="app-modal app-modal__full">
        <el-dialog
          :visible.sync="drawerDealCreate"
          :fullscreen="true"
          ref="drawerDealCreate"
          @opened="drawerOpened('drawerDealCreateChild')"
          @closed="drawerClosed('drawerDealCreateChild')"
        >
          <div
            @click="closeAddModel()"
            class="
              close-modal-stick
              d-flex
              align-center
              p-fixed
              text-white
              p-2
              font-bold
              pointer
              transition
            "
          >
            <div class="close-modal-icon text-center rounded-circle transition">
              <i class="el-icon-close"></i>
            </div>
            <span class="close-modal-text text-uppercase ml-2"></span>
          </div>
          <AppModal
            ref="drawerDealCreateChild"
            :board_id="board_id"
            :selectedDeal="selectedDeal"
            :dealCreate="dealCreate"
            drawer="drawerDealCreate"
          />
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import draggable from "vuedraggable";
import AppModal from "./components/add-modal";
import CrmCreateBoard from "./components/board/crm-create-board";
import CrmUpdateBoard from "./components/board/crm-update-board";
import CrmCreateDeal from "./components/deal/crm-create-deal";
import CrmUpdateDeal from "./components/deal/crm-create-deal";
import selectUser from "@/components/inventory/select-deal-users";
import selectFilial from "@/components/inventory/select-filial";
import selectCompany from "@/components/inventory/select-company";
import selectClient from "@/components/inventory/select-company-client";
import _ from 'lodash';

export default {
  name: "deals",

  components: {
    draggable,
    AppModal,
    CrmCreateBoard,
    CrmUpdateBoard,
    CrmCreateDeal,
    CrmUpdateDeal,
    selectCompany,
    selectClient,
    selectUser,
    selectFilial,
    
  },

  data() {
    return {
      form: {},
      rows: [],
      dateColorClass: 'coloa2', 
      loadingData: false,
      filterForm: {},
      boardForm: {},
      boardFormCreate: {},
      dealForm: {},
      drawerDealCreate: false,
      drawerDealUpdate: false,
      titleChangedRow: null,
      colorPicker: "#409EFF",
      newTaskTitle: "",
      drawerCreate: false,
      drawerBoardCreate: false,
      drawerBoardUpdate: false,
      drag: false,
      selectedItem: null,
      reloadList: false,
      board_id: null,
      selectedDeal: null,
      dealCreate: false,
    };
  },
  created() {
    this.filterForm = JSON.parse(JSON.stringify(this.filter));
    this.debouncedGetList = _.debounce(this.getList, 500);
  },
  watch: {
    filterForm: {
      handler: async function (newVal, oldVal) {
        await this.updateFilter(newVal);
         this.debouncedGetList();
      },
      deep: true,
      immediate: true,
    },
    list: {
      handler: function (newVal, oldVal) {
        this.rows = JSON.parse(JSON.stringify(this.list));
      },
      deep: true,
      immediate: true,
    },
    boardForm(val) {
      this.changeBoardIndex(this.boardForm);
    },
    dealForm(val) {
      this.changeDealIndex(this.dealForm);
    },
  },

  computed: {
    ...mapGetters({
      permissions: "auth/permissions",
      authUser: "auth/user",
      list: "boards/list",
      getBoardForm: "boards/form",
      getDealForm: "deals/form",
      filter: "boards/filter",
      mode: "MODE"
    }),
    
    boardDealsSumm() {
      return this.rows.map(function (row) {
        let summ = 0;
        row.deals.map(function (deal) {
          summ += deal.money_amount *  (deal.currency ? deal.currency.rate : 1);
        });
        return Number(summ.toFixed(2));
      });
    },
    totalSumm() {
      let summ = 0;

      this.rows.map(function (row) {
        row.deals.map(function (deal) {
          summ += deal.money_amount * (deal.currency ? deal.currency.rate : 1);
        });
      });
      return Number(summ.toFixed(2));
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    noMore() {
      return this.count >= 20;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  methods: {
    dayDiff(element) {
      let finish_date = new Date(element.finish_date);
      let current_date = new Date();
      let end_date = new Date(element.end_date);
      if (!isNaN(current_date) && !isNaN(end_date)) {
        if(!isNaN(finish_date) && finish_date.getFullYear() !== 1970){
         let timeDifference = Math.round((end_date.getTime() - finish_date.setHours(0,0,0))/(1000 * 3600 * 24));    
         return timeDifference;
        }else{
         let timeDifference = Math.round((end_date.getTime() - current_date.setHours(0,0,0))/(1000 * 3600 * 24));    
         return timeDifference;
        }
      } else {
        console.log("Enter valid date.");
      }
    },
    addModalCreate(board, dealCreate) {
      this.board_id = board.id;
      this.selectedDeal = null;
      this.dealCreate = dealCreate;
      this.drawerDealCreate = true;
    },
    addModalEdit(board, deal, dealCreate) {
      this.board_id = board.id;
      this.selectedDeal = deal;
      this.dealCreate = dealCreate;
      this.drawerDealCreate = true;
    },
    closeAddModel() {
      this.drawerDealCreate = false;
      if(this.reloadList === true){
        this.debouncedGetList();
      }
    },
    ...mapActions({
      updateList: "boards/index",
      changeBoardIndex: "boards/changeIndex",
      editBoard: "boards/show",
      saveBoard: "boards/store",
      updateBoard: "boards/update",
      destroyBoard: "boards/destroy",
      updateFilter: "boards/updateFilter",
      changeDealIndex: "deals/changeIndex",
      editDeal: "deals/show",
      empty: "deals/empty",
    }),

    getList() {
      this.loadingData = true
      this.updateList(this.filter)
        .then(() => {
          this.rows = JSON.parse(JSON.stringify(this.list));
          this.reloadList = false;
          this.loadingData = false
        })
        .catch(() => {
          this.loadingData = false
          this.$message({
            type: "info",
            message: "There is no tabs",
          });
        });
    },

    getEditedBoard(id, index) {
      this.editBoard(id)
        .then(() => {
          this.boardForm = this.getBoardForm;
          this.boardForm.index = index;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Sorry",
          });
        });
    },
    getEditedDeal(id, index, board_id) {
      this.editDeal(id)
        .then(() => {
          this.dealForm = this.getDealForm;
          this.dealForm.index = index;
          this.dealForm.board_id = board_id;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Sorry",
          });
        });
    },

    board: function (event) {
      this.getEditedBoard(event.moved.element.id, event.moved.newIndex);
    },

    deal: function (evt, row) {
      if (evt.moved || evt.added) {
        let event = "";
        if (evt.moved) {
          event = evt.moved;
        }
        if (evt.added) {
          event = evt.added;
        }

        this.getEditedDeal(event.element.id, event.newIndex, row.id);
      }
    },
    openDrawer() {
      this.drawerDealCreate = !this.drawerDealCreate;
    },
    deleteBoard(row) {
      this.$confirm(
        this.$t('message.do_you_want_to_delete_board'),
        this.$t('message.warning'),
        {
          confirmButtonText: this.$t('message.yes'),
          cancelButtonText: this.$t('message.no'),
          type: "warning",
        }
      )
        .then(() => {
          this.destroyBoard(row.id)
            .then(res => {
              if(res.data.result.success){
                this.debouncedGetList();
                this.$alert(res);
              }else{
                this.$warning_message(res);
              }

            })
            .catch(() => {
            });
        })
    },

    changeTitle(event, row) {
      let _el = event.target;
      _el.nextElementSibling.classList.add("active");
      _el.classList.add("_focus");
      _el.setAttribute("contenteditable", true);
    },
    editTaskTitle(row) {
      this.drawerBoardUpdate = true;

      this.selectedItem = row;
    },
    changeTaskTitle() {
      this.titleChangedRow.title = this.newTaskTitle;
      this.titleChangedRow.titleDefaultColor = this.colorPicker;

      this.editTaskTitleModal = false;
    },
    changeColor(val) {
      this.colorPicker = val;
    },

    closeDrawer(drawer) {
      this.drawerBoardCreate = false;
      this.drawerBoardUpdate = false;
      if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
        this.$refs[drawer].closeDrawer();
      }
    },
    edit(model) {
      this.selectedItem = model;
      this.drawerUpdate = true;
    },
    drawerClosed(ref) {
      if (this.$refs[ref]) {
        this.$refs[ref].closed();
      }
      if (this.reloadList === true) {
        this.debouncedGetList();
      }
      if (_.isFunction(this.empty)) {
        this.empty();
      }
    },
    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened();
        }
      }
    },

    listChanged() {
      this.reloadList = true;
    },

    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$alert(res);
          this.fetchData();
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
  },
};
</script>


<style>

.offer_style {
  background: rgb(11, 224, 11);
  color: white;
  border-radius: 3px;
  border: 1px solid rgb(11, 224, 11);
  padding: 1px;
}

.summa-task {
  background: whitesmoke;
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
}

.coloa3 {
   background: #ff3f3f;
}
.type-task-big {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.type-task{
  color: #686868;
}
.bottom-data-task{
      margin-top: 5px;
}
.bottom-data-task span{
  margin-left: 0px;
  margin-right: 5px;
}

</style>
