<template>
    <div class="task-form" v-loading="loadingData">
        <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-position="top"
        >
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item
                        :label="$t('message.name')"
                        class="label_mini"
                        prop="name"
                        >
                        <el-input
                            :placeholder="$t('message.name')"
                            v-model="form.name"
                            size="medium"
                            autocomplete="off"
                        ></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    
                    <el-form-item
                        :label="$t('message.color')"
                        class="label_mini"
                        prop="color"
                        >
                        <div class="color-picker-wrap d-flex">
                            <el-color-picker
                                v-model="form.color"
                                @active-change="changeColor"
                            ></el-color-picker>
                            <div class="picker-box ml-4 rounded w-100 p-1">
                                <div
                                    class="h-100 rounded-sm"
                                    :style="{ background: form.color }"
                                ></div>
                            </div>
                        </div>
                    </el-form-item>

                </el-col>

                <el-col :span="24">
                    <el-form-item :label="$t('message.filials')" class="label_mini" prop="filial_id">
                        <select-filial
                            :size="'medium'"
                            :placeholder="$t('message.filial')"
                            :id="form.filial_id"
                            v-model="form.filial_id"
                          >
                          </select-filial>
                    </el-form-item>
                </el-col>

            </el-row>
            
        </el-form>

        <el-button
            class="mt-4"
            type="primary"
            icon="el-icon-edit"
            size="medium"
            @click="submit(true)"
        >
            {{ $t("message.update") }}
        </el-button>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import selectFilial from "@/components/inventory/select-filial";

export default {
    mixins: [form, drawer, show],
    components: {
        selectFilial,
    },
    name: "boards",
    data() {
        return {
        };
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "boards/rules",
            model: "boards/model",
            columns: "boards/columns",
        }),
    },
    methods: {
        ...mapActions({
            update: "boards/update",
            show: "boards/show",
            empty: 'boards/emptyModel'
        }),
        changeColor(val) {
            this.form.color = val;
        },
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (res.status == 200) this.close();
                            this.empty();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
